import { MagicSymbols } from "../utility/magic-symbols";

const MagicSelection = (props) => {
    let symbolsArray;
    if (props.level === "top") {
        symbolsArray = MagicSymbols.topRow;
    } else {
        symbolsArray = MagicSymbols.bottomRow;
    }

    if (symbolsArray[props.selectedItem].symbol !== " ") {
        return (
            <div>
                <div>{ symbolsArray[props.selectedItem].description }</div>
                <div>({ symbolsArray[props.selectedItem].sound })</div>
            </div>
        );
    }

    return "";
}

export default MagicSelection;