import { Button } from "react-bootstrap";
import { MagicSymbols } from "../utility/magic-symbols";

const ClickToCopy = (props) => {
    const copyToClipboardHandler = () => {
        navigator.clipboard.writeText(magicSpellWords);
    }

    let magicSpellTop = MagicSymbols.topRow[props.top].symbol;
    let magicSpellBottom = MagicSymbols.bottomRow[props.bottom].symbol;
    let magicSpellWords = ""; 
    if (magicSpellTop === " ") {
        magicSpellWords = magicSpellBottom;
    } else {
        if (magicSpellBottom === " ") {
            magicSpellWords = magicSpellTop;
        } else {
            magicSpellWords = magicSpellTop + magicSpellBottom;
        }
    }

    if (magicSpellWords === "") {
        return "";
    }

    return (
        <Button variant="primary" onClick={copyToClipboardHandler}>
            Copy "{magicSpellWords}"
        </Button>
    );
}

export default ClickToCopy;