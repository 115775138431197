import { Col, Container, Row } from "react-bootstrap";
import { MagicSymbols } from "../utility/magic-symbols";
import MagicSelection from "./MagicSelection";
import ClickToCopy from "./ClickToCopy";

const MagicResult = (props) => {
    let magicEffect = MagicSymbols.magicEffects[props.top][props.bottom];
    let magicSpellTop = MagicSymbols.topRow[props.top].sound;
    let magicSpellBottom = MagicSymbols.bottomRow[props.bottom].sound;
    let magicSpellWords = ""; 
    if (magicSpellTop === "") {
        magicSpellWords = magicSpellBottom;
    } else {
        if (magicSpellBottom === "") {
            magicSpellWords = magicSpellTop;
        } else {
            magicSpellWords = magicSpellTop + "-" + magicSpellBottom;
        }
    }

    if (magicSpellWords === "") {
        return "";
    }

    return (
        <Container>
            <Row>
                <Col className="d-flex align-items-center justify-content-center">
                    <MagicSelection level="top" selectedItem={props.top} />
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                    +
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                    <MagicSelection level="bottom" selectedItem={props.bottom} />
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                    =
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                    <Container>
                        <Row>
                            <Col>
                                {magicEffect}                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                ({magicSpellWords})
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ClickToCopy top={props.top} bottom={props.bottom} />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default MagicResult;