import "./MagicCarouselItem.css";

const MagicCarouselItem = (props) => {
    return (
        <div className="carouselCard" key={"Card" + props.level + props.item.symbol}>
            { props.item.symbol === " " ? <span>&nbsp;</span> : props.item.symbol }
        </div>
    );
}

export default MagicCarouselItem;