import Carousel from "react-bootstrap/Carousel";
import { MagicSymbols } from "../utility/magic-symbols";

import "./MagicCarousel.css";
import MagicCarouselItem from "./MagicCarouselItem";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const MagicCarousel = (props) => {
    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
        props.updateFunction(selectedIndex);
    };
    const handleSlid = () => {
        props.updateFunction(index);
    };

    let symbolsArray;
    if (props.level === "top") {
        symbolsArray = MagicSymbols.topRow;
    } else {
        symbolsArray = MagicSymbols.bottomRow;
    }

    const prevIcon = <span class="carouselArrows"><FontAwesomeIcon icon={icon({name: 'arrow-left'})} size="lg" /></span>;
    const nextIcon = <span class="carouselArrows"><FontAwesomeIcon icon={icon({name: 'arrow-right'})} size="lg" /></span>;
    
    return (
        <Carousel
                prevIcon={prevIcon}
                nextIcon={nextIcon}
                activeIndex={index}
                onSelect={handleSelect}
                onSlid={handleSlid}
                slide={false}
                interval={null}
                indicators={false}
                key={"carousel" + props.level}
                prevLabel=""
                nextLabel=""
        >
            {symbolsArray.map(item => {
                return (
                    <Carousel.Item key={"carouselItem" + props.level + item.symbol}>
                        <MagicCarouselItem level={props.level} item={item} />
                    </Carousel.Item>
                );
            })}
        </Carousel>
    )
}

export default MagicCarousel;