export const MagicSymbols = {
    "topRow": [
        {
            "symbol": " ",
            "sound": "",
            "description": ""
        },
        {
            "symbol": "⤺",
            "sound": "Noo",
            "description": "Move"
        },
        {
            "symbol": "ⴴ",
            "sound": "Lesh",
            "description": "Combine"
        },
        {
            "symbol": "ꟿ",
            "sound": "Esh",
            "description": "Fire"
        },
        {
            "symbol": "∿",
            "sound": "Mai",
            "description": "Water"
        },
        {
            "symbol": "ᕲ",
            "sound": "Av",
            "description": "Air"
        },
        {
            "symbol": "⩜",
            "sound": "Doo",
            "description": "Earth"
        },
        {
            "symbol": "ᘐ",
            "sound": "Ayne",
            "description": "Mana"
        }
    ],
    "bottomRow": [
        {
            "symbol": " ",
            "sound": "",
            "description": ""
        },
        {
            "symbol": "⤻",  
            "sound":	"Ah",
            "description":	"Move"
        },
        {
            "symbol": "ⴳ",
            "sound":	"Lef",
            "description":	"Split"
        },
        {
            "symbol": "ꟿ",
            "sound":	"Esh",
            "description":	"Fire"
        },
        {
            "symbol": "∿",
            "sound":	"Mai",
            "description":	"Water"
        },
        {
            "symbol": "ᕲ",
            "sound":	"Av",
            "description":	"Air"
        },
        {
            "symbol": "⩜",
            "sound":	"Doo",
            "description":	"Earth"
        },
        {
            "symbol": "ᘓ",
            "sound":	"Eba",
            "description":	"Mind"
        }
    ],
    magicEffects: [
        ["", "Move", "Fuse", "Fire", "Water", "Air", "Earth", "UNKNOWN"],
        ["Move", "Portal ", "Speed", "Absorb", "Vibration/Sound", "UNKNOWN", "UNKNOWN", "Mana Shift"],
        ["Split", "Shield", "Reshape", "Explode", "UNKNOWN", "UNKNOWN", "Expand", "Drain"],
        ["Fire", "UNKNOWN", "Bind", "Fire Wall", "Steam", "Lightning", "Lava", "UNKNOWN"],
        ["Water", "UNKNOWN", "UNKNOWN", "Steam", "Tidal Wave", "Mist (Duplication)", "Mud", "UNKNOWN"],
        ["Air", "Float", "UNKNOWN", "Lightning", "Mist (Duplication)", "Tornado", "Sand", "UNKNOWN"],
        ["Earth", "UNKNOWN", "Grow", "Lava", "Mud", "Sand", "Earthquake", "UNKNOWN"],
        ["UNKNOWN", "Mind Control (Move Mind)", "Link ", "Illusion", "Hypnotize", "Heal", "Lineage", "Mind Reach"]
    ]
}
