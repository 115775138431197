import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';

import MagicCube from './MagicCube';
import MagicResult from './MagicResult';

const MainPage = () => {
    const [topIndex, setTopIndex] = useState(0);
    const [bottomIndex, setBottomIndex] = useState(0);

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Magic Cube</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <MagicCube updateTopHandler={setTopIndex} updateBottomHandler={setBottomIndex} />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex align-items-center">
                    <hr className="MidLine" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <MagicResult top={topIndex} bottom={bottomIndex} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                     &copy;2023 - TechyDad
                </Col>
            </Row>
            <Row>
                <Col>
                    <a 
                        href="https://iconscout.com/icons/cube" 
                        target="_blank" 
                        rel="noreferrer"
                    >Favicon (Cube)</a>
                    &nbsp;by&nbsp; 
                     <a 
                        href="https://iconscout.com/contributors/eva-icons" 
                        target="_blank"
                        rel="noreferrer"
                    >Akveo</a>
                </Col>
                <Col>
                    <a 
                        href="https://pixabay.com/photos/abstract-wall-backdrop-background-1850424/" 
                        target="_blank"
                        rel="noreferrer"
                    >Abstract Wall Backdrop Background</a>
                    &nbsp;by Pexels via PixelBay
                </Col>
            </Row>
        </Container>
    );
}

export default MainPage;