import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MagicCarousel from "./MagicCarousel";

import "./MagicCube.css";

const MagicCube = (props) => {
    return (    
        <Container className="stoneBG">
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <MagicCarousel level="top" updateFunction={props.updateTopHandler} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MagicCarousel level="bottom" updateFunction={props.updateBottomHandler}  />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default MagicCube;